import '../tooltip/Tooltip.css'

import React, { createContext, useContext, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

interface TooltipState {
  visible: boolean
  title: string | null
  position: { x: number; y: number }
}

interface TooltipContextType {
  setTooltip: (tooltip: TooltipState) => void
}

const TooltipContext = createContext<TooltipContextType | undefined>(undefined)

export const TooltipProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [tooltip, setTooltip] = useState<TooltipState>({
    visible: false,
    title: null,
    position: { x: 0, y: 0 },
  })

  return (
    <TooltipContext.Provider value={{ setTooltip }}>
      {children}
      <GlobalTooltip tooltip={tooltip} />
    </TooltipContext.Provider>
  )
}

export const useTooltip = () => {
  const context = useContext(TooltipContext)
  if (!context) {
    throw new Error('useTooltip must be used within a TooltipProvider')
  }
  return context
}

export const GlobalTooltip: React.FC<{ tooltip: TooltipState }> = ({
  tooltip,
}) => {
  return createPortal(
    <div className="tooltip-element-wrapper">
      <div
        className="tooltip-title"
        style={{
          position: 'absolute',
          top: tooltip.position.y,
          left: tooltip.position.x,
          display: tooltip.visible ? 'block' : 'none',
        }}
      >
        {tooltip.title}
      </div>
    </div>,
    document.body
  )
}

export const Tooltip: React.FC<{
  title: string
  children: React.ReactNode
}> = ({ title, children }) => {
  const { setTooltip } = useTooltip()

  useEffect(() => {
    return () => {
      setTooltip({ visible: false, title: null, position: { x: 0, y: 0 } })
    }
  }, [])
  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    setTooltip({
      visible: true,
      title,
      position: { x: e.pageX + 10, y: e.pageY + 20 },
    })
  }

  const handleMouseLeave = () => {
    setTooltip({ visible: false, title: null, position: { x: 0, y: 0 } })
  }

  return (
    <div
      className="tooltip-element-wrapper"
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      {children}
    </div>
  )
}

export default GlobalTooltip
