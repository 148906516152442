import { t } from "@config/language";

const getPartnerDelimiters = (path: string) => {
    const scope = path.split('/')[1]
    let tagId: string | undefined;
    const regex = new RegExp(`^/${t('nav.explore.link')}/[^/]+/(\\d+)/`)

    const match = path.match(regex)
    
    if (match) {
        tagId = match[1]
    } 
    return { scope, tagId }
}

export default getPartnerDelimiters